@import "./variables_mixins/variables";

.recaptcha-legal{
  font-size: .9rem;
  color: #666;
  line-height: .95rem;
}

.system_notification_banner {
  background-color: #acbbc1;
  border: 1px dotted #9faaa9;
  color: black;
}

.login-form {
  padding: 0 4.5% 1% 4.5%;
  background-color: $lighter-grey;

  &.login-form-tight{
    padding: 0 3% 1% 3%;
  }
}

.sam-links {
  margin-top: 20px;
  background-color: $lighter-grey;
  padding: 1% 1.9% 2.2% 1.9%;
  text-align: center;
  h6{
  	font-weight: bold;
  	margin-top: 0.5rem;
  }
}

.waiver-links {
  @extend .sam-links;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em;
}

.create-account {
  text-align: center;
}

.sam-link {
  display: block;
}

#browsers_supported {
	display: inline-block;
  ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    li {
      display: inline-block;
      height: 32px;

      img {
        height: 100%;
        vertical-align: middle;
      }
    }
  }

  span {
    line-height: 24px;
    margin-right: 12px;
    font-size: 0.750rem;
  }
}
